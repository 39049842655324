import * as Style from './style';

export interface PageIntroProps {
  title: string;
  isHomepage: boolean;
}

const TitleBox = ({ title, isHomepage = false }: PageIntroProps) => {
  return (
    <Style.PageIntro isHomepage={isHomepage as boolean}>
      <Style.Title>{title}</Style.Title>
    </Style.PageIntro>
  );
};

export default TitleBox;
